<template>
  <div class="mtop-200 width-center-80 font-sfns">
    <b-container fluid class="main-transactions">
      <b-row class="m-filter">
        <b-col cols="12">
         <p>Coming soon...</p>
        </b-col>
        <transition name="fade">
        </transition>
      </b-row>
  
<!--      Chart -->
   

    
    </b-container>
  </div>
</template>


<script>
import _ from 'lodash'
// import { ValidationObserver} from 'vee-validate'
// import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/index.css'
import i18n from '@/i18n/i18nData'
import utils from "@/helper/utils.js"
// import VueApexCharts from 'vue-apexcharts'
import exportFromJSON from 'export-from-json'
import {formatLabel} from '../store/transaction/moduleTransactionActions';

export default {
  title: "Statistics",
  data() {
    return {
      sortBy: 'name',
      windowWidth: window.innerWidth,
      sortDesc: false,
      isBusy: true,
      currentPage: 1,
      perPage: 0,
      paginate: 12,
      filterActive: false,
      classification: "",
      sub_classification: "",
      invoice_no: "",
      city_id: "",
      min_amount: "",
      yearRange: "",
      plan_no: [],
      plot_no: '',
      district: "",
      max_amount: "",
      from_meter_sq: "",
      to_meter_sq: "",
      hij_start_date: "",
      hij_end_date: "",
      trans_with_coord: "",
      stat_loading: false,
      transact_loading: false,
      term: "",
      login_error: null,
      fields: [
        { key: 'action', label: i18n.ar['action']},
        { key: 'price_per_sq_meter', label: i18n.ar['price_per_sq_meter'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'amount', label: i18n.ar['amount'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'hij_date', label: i18n.ar['hij_date'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'meter_sq', label: i18n.ar['meter_sq'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        //{ key: 'invoice_no', label: i18n.ar['invoice_no'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'sub_classification', label: i18n.ar['sub_classification'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'classification', label: i18n.ar['classification'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'plot_no', label: i18n.ar["plot_no"], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'plan', label: i18n.ar["plan_no"], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'district', label: i18n.ar['district'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
        { key: 'city', label: i18n.ar['city'], sortable: true, thClass: "m-heading", tdClass: "m-data"},
      ],
      classifications: [
        {value: "", text: i18n.ar["select_classification"]},
        {value: "Commercial", text: i18n.ar["commercial"]},
        {value: "Residential", text: i18n.ar["residential"]}
      ],
      coordOptions: [
        {value: true, text: "True"},
        {value: "", text: "False"}
      ],
      json_fields: {
        'ID': 'id',
        'Plot ID': 'plot_id',
        'رقم القطعة': 'plot_no',
        'Plot Coordinates': 'plot_coord',
        'رقم المخطط': 'plan',
        'Blocks': 'blocks',
        'Invoice No': 'invoice_no',
        'EN Date': 'en_date',
        'HIJ Date': 'hij_date',
        'مساحة القطعة': 'meter_sq',
        'Amount': 'amount',
        'Classification': 'classification',
        'Sub classification': 'sub_classification',
        'City': 'city',
        'City ID': 'city_id',
        'District': 'district',
        'Price Per Meter sq': 'price_per_sq_meter',
        'Created At': 'created_at',
        'Updated At': 'updated_at'
      }
    }
  },
  components: {
    // DatePicker,
    // ValidationObserver,
    // VueApexCharts,
  },
  computed: {
    chartCategoryType() {
      return this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[0].categories
          && this.isNumeric(this.$store.state.transaction.statOne[0].categories[0]) ? 'year' : 'month'
    },
    transactions() {
      return this.$store.state.transaction.transactions
    },
    cities() {
      return this.$store.getters["city/listCities"]
    },
    subClassifications() {
      if (this.classification == "") return []

      let classifications = this.$store.state.transaction.classifications

      let clas = []

      classifications.forEach((cla) => {
        if (cla.classification === this.classification) {
          let name = cla.name.toLowerCase().split(" ").join("_")
          clas.push({value: cla.id, text: i18n.ar[name]})
        }
      })
      return clas
    },
    rows() {
      return this.$store.state.transaction.meta.total
    },
    iconText() {
      return this.filterActive ? "chevron-up" : "chevron-down"
    },
    plans() {
      let data = []
      this.$store.state.plan.plans2.map(item => data = [...data, item.planNo])
      return [...new Set(data)]
    },
    statOne() {
      return {
        type: 'line',
        chartOptions: {
          chart: { id: 'basic-bar'},
          xaxis: {
            categories: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[0].categories
                ? this.$store.state.transaction.statOne[0].categories
                : []
          }
        },
        series: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[0].series
            ? this.$store.state.transaction.statOne[0].series
            : []
      }
    },
    statFive() {
      return {
        type: 'line',
        chartOptions: {
          chart: { id: 'basic-bar'},
          yaxis: {
            labels: {
              formatter: function (value) {return formatLabel(value, 1)}
            },
          },
          xaxis: {
            categories: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[1].categories
                ? this.$store.state.transaction.statOne[1].categories
                : []
          }
        },
        series: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[1].series
            ? this.$store.state.transaction.statOne[1].series
            : []
      }
    },
    statSix() {
      return {
        chartOptions: {
          chart: { id: 'basic-bar'},
          yaxis: {
            labels: {
              formatter: function (value) {return formatLabel(value, 1)}
            },
          },
          xaxis: {
            categories: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[2].categories
                ? this.$store.state.transaction.statOne[2].categories
                : []
          }
        },
        series: this.$store.state.transaction.statOne.length && this.$store.state.transaction.statOne[2].series
            ? this.$store.state.transaction.statOne[2].series
            : []
      }
    },
    statTwo() {
      return {
        type: 'bar',
        chartOptions: {
          chart: { id: 'basic-bar' },
          yaxis: {
            labels: {
              formatter: function (value) {return formatLabel(value, 1)}
            },
          },
          xaxis: {
            categories: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[0].categories
                ? this.$store.state.transaction.statTwo[0].categories
                : []
          }
        },
        series: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[0].series
            ? this.$store.state.transaction.statTwo[0].series
            : []
      }
    },
    statThree() {
      return {
        chartOptions: {
          chart: { id: 'basic-bar' },
          xaxis: {
            categories: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[1].categories
                ? this.$store.state.transaction.statTwo[1].categories
                : []
          }
        },
        series: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[1].series
            ? this.$store.state.transaction.statTwo[1].series
            : []
      }
    },
    statFour() {
      return {
        chartOptions: {
          chart: { id: 'basic-bar' },
          yaxis: {
            labels: {
              formatter: function (value) {return formatLabel(value, 1)}
            },
          },
          xaxis: {
            categories: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[2].categories
                ? this.$store.state.transaction.statTwo[2].categories
                : []
          }
        },
        series: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[2].series
            ? this.$store.state.transaction.statTwo[2].series
            : []
      }
    }
    // statTwo: function () {
    //   return id => {
    //     return {
    //       chartOptions: {
    //         chart: { id: 'basic-bar' },
    //         xaxis: {
    //           categories: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[id].categories
    //               ? this.$store.state.transaction.statTwo[id].categories
    //               : []
    //         }
    //       },
    //       series: this.$store.state.transaction.statTwo.length && this.$store.state.transaction.statTwo[id].series
    //           ? this.$store.state.transaction.statOne[id].series
    //           : []
    //     }
    //   }
    // }
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.transaction.meta.current_page)
        this.fetchTransactions(this.currentPage)
    },
  },
  methods: {
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    filterTransactions: _.debounce(function() {
      this.fetchTransactions(this.currentPage)
    }, 2000),
    onChangeFetch() {
      this.fetchTransactions(1, false)
    },
    fetchTransactions(current_page = 1, noLoad) {
      this.transact_loading = true
      // let loader = this.$loading.show({opacity: 0.9})

      // if (noLoad === true) loader.hide()
      if (noLoad === true) this.transact_loading = false

      let plans = ''
      this.plan_no.map(item => plans = `${plans}${plans === '' ? '' : ','}${item}`)

      const payload = {
        meta: {
          page: current_page,
          paginate: this.paginate,
          classification: this.classification,
          sub_classification_id: this.sub_classification,
          invoice_no: this.invoice_no,
          city_id: this.city_id,
          min_amount: this.min_amount,
          start_date: this.yearRange.length ? this.yearRange[0] : "",
          end_date: this.yearRange.length ? this.yearRange[1]: "",
          plan_no: plans,
          plot_no: this.plot_no,
          district: this.district,
          max_amount: this.max_amount,
          from_meter_sq: this.from_meter_sq,
          to_meter_sq: this.to_meter_sq,
          hij_start_date: this.hij_start_date,
          hij_end_date: this.hij_end_date,
          trans_with_coord: this.trans_with_coord
        },
      }

      if (this.plan_no.length) {
        let newDate = {
          start_date: this.yearRange.length ? this.yearRange[0] : "",
          end_date: this.yearRange.length ? this.yearRange[1]: "",
        }
        let payload2 = {
          meta: { stat_type: 'plan', stat_type_value: this.plan_no[0] }
        };
        if (this.yearRange.length) payload2 = {meta: {...payload2.meta, ...newDate}}
        this.stat_loading = true
        this.$store.dispatch('transaction/fetchStatOne', payload2)
            .then(() => {
              this.stat_loading = false
              this.login_error = null
            })
            .catch(error => {
              this.stat_loading = false
              this.login_error = error
            })
        let payload3 = {meta: { plan: this.plan_no[0] }}
        if (this.city_id) payload3 = {meta: {...payload3.meta, city_id: this.city_id }}
        if (this.yearRange.length) payload3 = {meta: {...payload3.meta, ...newDate}}
        this.$store.dispatch('transaction/fetchStatTwo', payload3)
      }

      for (const [key, value] of Object.entries(payload.meta)) {
        if(value == "")
          delete payload.meta[key]
      }
      this.$store.dispatch("transaction/fetchTransactions", payload)
      .then(() => this.transact_loading = false)
      .catch(() => this.transact_loading = false)
    },
    fetchAllCities() {
      const payload = {
        meta: {
          page: 1,
          paginate: 200
        },
      }
      this.$store.dispatch("city/fetchAllCities", payload)
    },
    toggleFilter() {
      this.filterActive = !this.filterActive
    },
    transText(value) {
      return value ? i18n.ar[value.toLowerCase().split(" ").join("_")] : ''
    },
    exportData() {
      const data = JSON.parse(JSON.stringify(this.transactions))
      const fileName = "transactions"
      const exportType = "csv"

      exportFromJSON({ data, fileName, exportType})
    },
    joinCoords(coord) {
      return (coord === null) ? "" : coord.join(",")
    },
    formatNumber(number) {
      return utils.numberFormat(number)
    },
    onOptionClick({ option, addTag }) {
      addTag(option); this.term = ''
      setTimeout(() => this.fetchTransactions(), 500)
    },
    fetchPlans(query) {
      let payload = { meta: { q: query, paginate: 1000 } }
      if (this.city_id) payload = {...payload, meta: { ...payload.meta, city_id: this.city_id}}
      query.length > 1 && this.$store.dispatch("plan/fetchPlans2", payload)
    },
    clearPlans() {
      this.plan_no = []
      this.$store.dispatch("plan/clearPlans")
    }
  },
  created() {
    this.$store.dispatch("transaction/fetchClassifications", {meta: {page: 1, paginate: 200}})
    this.fetchTransactions()
    this.fetchAllCities()
  }

}
</script>

<style lang="scss">
.chart-title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 25px;
  color: #333;
}
.error-title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  color: #333;
  width: 100%;
}
.spinner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.dropdown-menu.show {
  overflow: scroll;
  height: 300px;
}
</style>
